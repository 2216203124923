import React from 'react'

import avatar from '../images/info/avatar.jpg'
import avatarTransparent from '../images/info/avatar-nobg.png'
import avatarFull from '../images/info/avatar-full.jpg'
import Box from '../styled/Box'
import FlexGrid from '../styled/FlexGrid'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import SEO from '../components/SEO'

const InfoPage = () => (
  <Layout>
    <SEO title="Info" />
    <PageHeader
      title="Info"
      description="How to spell and pronounce my name, headshots, and my bio."
    />
    <Box mb="xl">
      <Box as="h2" mb="base">
        Name
      </Box>
      <Box as="p" mb="base">
        My name is Drew Barontini, <Box as="strong">NOT</Box> Andrew Barontini
        (I will show you my birth certificate).
      </Box>
      <Box as="p">
        My last name is pronounced <code>BEAR-UN-TEENY</code>.
      </Box>
    </Box>
    <Box mb="xl">
      <Box as="h2" mb="base">
        Headshots
      </Box>
      <Box as="p" mb="l">
        Right-click and save the photo.
      </Box>
      <FlexGrid display={{ _: 'block', md: 'flex' }} mb="l">
        <FlexGrid.Item flexBasis="50%">
          <Box as="img" src={avatar} width="400px" mb="s" />
          <Box as="p" color="medium" fontSize="xs" textAlign="center">
            Standard headshot, with background
          </Box>
        </FlexGrid.Item>
        <FlexGrid.Item flexBasis="50%">
          <Box as="img" src={avatarTransparent} width="400px" mb="s" />
          <Box as="p" color="medium" fontSize="xs" textAlign="center">
            Standard headshot, without background
          </Box>
        </FlexGrid.Item>
      </FlexGrid>
      <Box as="img" src={avatarFull} width="400px" mb="s" mx="auto" />
      <Box as="p" color="medium" fontSize="xs" textAlign="center">
        Full headshot, with background
      </Box>
    </Box>
    <Box mb="xl">
      <Box as="h2" mb="base">
        Bio
      </Box>
      <Box mb="xl">
        <Box as="h3" mt="l">
          Long, First-Person
        </Box>
        <Box as="p">
          Hi! I'm Drew Barontini, Product Director at Differential. For over 10
          years, I've helped build digital products for a variety of
          organizations. I’m passionate about solving problems with design,
          programming, and productivity systems to deliver effective solutions.
          I work remotely in sunny Orlando, FL with my beautiful wife, son,
          step-daughter, and pug.
        </Box>
      </Box>
      <Box mb="xl">
        <Box as="h3" mt="l">
          Long, Third-Person
        </Box>
        <Box as="p">
          Drew Barontini is the Product Director at Differential. For over 10
          years, he's helped build digital products for a variety of
          organizations. He's passionate about solving problems with design,
          programming, and productivity systems to deliver effective solutions.
          He works remotely in sunny Orlando, FL with his beautiful wife, son,
          step-daughter, and pug.
        </Box>
      </Box>
      <Box mb="xl">
        <Box as="h3" mt="l">
          Short, First-Person
        </Box>
        <Box as="p">
          Hi! I'm Drew Barontini, Product Director at Differential. I solve
          problems and deliver effective solutions with design, programming, and
          productivity systems.
        </Box>
      </Box>
      <Box mb="xl">
        <Box as="h3" mt="l">
          Short, Third-Person
        </Box>
        <Box as="p">
          Drew Barontini is the Product Director at Differential. He solves
          problems and delivers effective solutions with design, programming,
          and productivity systems.
        </Box>
      </Box>
    </Box>
  </Layout>
)

export default InfoPage
